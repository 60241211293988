import { PATH } from "routes/path";
import { LANDING_APP_URL } from "./config";

export const PAGE_SIZE = 10;
export const YEARLY_EBAY_PLAN_SAVE_AMOUNT = 359;
export const YOUTUBE_CHANNEL =
  "https://www.youtube.com/channel/UC6RxjqZyhTKshYyBJcIIvPQ";

export const FACEBOOK_GROUP = "https://localhost/groups/373557291131694";

export const LANDING_PAGE = LANDING_APP_URL;
export const GTM_TRACKING_ID = "GTM-K3QMPNS";
export const LANDING_PAGE_LINKS = {
  root: "https://za01.groupbuy.pk/_www",
  home: LANDING_PAGE,
  ebayMarketPlace: LANDING_PAGE + "ebay-product-research/",
  aliexpressMarketPlace: LANDING_PAGE + "aliexpress-product-research/",
  zikProTools: LANDING_PAGE + "ebay-seller-tools/",
  dropshippingTool: LANDING_PAGE + "ebay-dropshipping-tools/",
  dropshippingCourse: LANDING_PAGE + "ebay-dropshipping-course/",
  ebayTitleBuilder: LANDING_PAGE + "ebay-title-builder/",
  registration: LANDING_PAGE + "Registration",
  pricing: LANDING_PAGE + "pricing/",
  r: LANDING_PAGE + "r",
  homeV7: LANDING_PAGE + "home/v7",
};

export const COOKIE_DOMAIN = { domain: process.env.REACT_APP_DOMAIN_URL };

export const COUNTRY_FLAGS = "https://za01.groupbuy.pk/_zikmedia/Flags";

export const YOUTUBE_BASE = "https://www.youtube.com/embed";

export const VIMEO_BASE = "https://player.vimeo.com/video";

export const AMAZON_SITE = "https://www.amazon.com";

export const ALIEXPRESS_SITE = "https://www.aliexpress.com";

export const CJ_DROPSHIPPING = "https://cjdropshipping.com";

export const WALMART_SITE = "https://www.walmart.com";

export const GOOGLE_LENS = "https://lens.google.com";

export const COMING_SOON = "Coming Soon";

export const CAN_NOT_ACCESS_PATH = PATH.managementPanel.managePayments;
export const PLANS = {
  PRO: ["StarterY", "StarterM", "ProLT"],
  PRO_PLUS: [
    "StarterY",
    "StarterM",
    "StandardY",
    "StandardM",
    "ProLT",
    "ProPlusLT",
  ],
};
export const REGISTRATION_BACKGROUND =
  "https://za01.groupbuy.pk/_zikmedia/current_zik_dashboard.svg";

export const GOOGLE_CAPTCHA_KEY = "6LcRPBgUAAAAAF4w1Kr2gk0ZNWiWZJgVCsV_n3dv";

export const THEME_COLORS = {
  primaryPurple: "#6831FF",
  smoothGreen: "#1BCFBA",
  spaceGreen: "#06FFC3",
  secondary: "#C8E1FF",
  yellow: "#E9BA1F",
  darkBlue: "#150D3F",
  electricBlue: "#1D41FF",
  neutral1: "#F6F6F6",
  neutral2: "#ECF5FF",
  neutral3: "#B0B6BD",
  success: "#51DD20",
  errorRed: "#C93A1B",
  errorOrange: "#DD6B20",
  grey900: "#151515",
  grey800: "#1B1B1B",
  grey700: "#383838",
  grey600: "#606060",
  grey500: "#5C5C5C",
  grey400: "#7B7B7B",
  grey300: "#9F9F9F",
  grey200: "#CACACA",
  grey100: "#E3E3E3",
  black900: "#252525",
  black800: "#1F1F1F",
  black700: "#171717",
  deepSpace900: "#0D0D0D",
  deepSpace800: "#161616",
  deepSpace700: "#242424",
  jeansBlue: "#002450",
  canaryYellow: "#FEC301",
  purple500: "#7B4BFF",
  lilac400: "#C8C0F2",
  paradiseBlue: "#21E2CC",
};
