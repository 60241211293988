import "./loading.css"


const index = () => (
    <div className="min-h-[100vh] p-[20px] flex justify-center items-center bg-white">
        <div className="max-w-[580px] flex justify-center items-center flex-col gap-[41px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="74" height="32" viewBox="0 0 74 32" fill="none">
                <path d="M25.4697 0V2.91295L10.2149 25.8054H25.5938V31.9375H0.5V29.3832L16.1803 5.70344H1.53708V0H25.4697Z" fill="#150D3F" />
                <path d="M37.7611 0H31.6777V31.9375H37.7611V0Z" fill="#150D3F" />
                <path d="M53.1654 0V14.2722L64.2113 0H71.7521V0.349809L58.8971 16.0912L73.5003 31.509V31.9375H65.8987L53.1654 18.1026V31.9375H46.8857V0H53.1654Z" fill="#150D3F" />
            </svg>
            <div className="text-[28px] font-bold leading-[44px]">🛠️  Website under Maintenance!  🛠️</div>
            <div className="font-medium text-[18px] leading-[28px]">
                <div className="mb-[35px]">Hey there! We’re currently enhancing our performance to serve you better. Please bear with us for a short while.</div>
                <div><span className="font-bold">Need Assistance?</span> Don’t hesitate to reach out!</div>
                <div><span className="font-bold">Email:</span> <span className="text-primaryPurple dark:text-purple500">support@za01.groupbuy.pk/_www</span></div>
                <div><span className="font-bold">Live Chat:</span> Find us on the bottom right corner.</div>
                <div className="my-[35px]">Thank you for your patience and understanding.</div>
                <div>
                    Warm Regards,<br />
                    <span className="font-bold">Zik Team</span>
                </div>
            </div>
            <div className="flex gap-[6px] w-[48px] h-[48px] items-center justify-center">
                <div className="w-[8px] h-[8px] bg-[#E3E3E3] rounded-[50px] mtnFirstLine" />
                <div className="w-[8px] h-[8px] bg-[#E3E3E3] rounded-[50px] mtnSecondLine" />
                <div className="w-[8px] h-[8px] bg-[#E3E3E3] rounded-[50px] mtnThirdLine" />
            </div>
        </div>
    </div>
)

export default index