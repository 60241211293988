import { ReactComponent as Facebook } from "assets/icons/facebook.svg";
import { ReactComponent as Instagram } from "assets/icons/instagram.svg";
import { ReactComponent as Linkedin } from "assets/icons/linkedin.svg";
import { ReactComponent as Twitter } from "assets/icons/Twitter.svg";
import { ReactComponent as Youtube } from "assets/icons/youtube.svg";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const iconColor = {
    color: "#C8E1FF",
};

const hoverIconColor = {
    color: "#1BCFBA",
};

export const socialIcons = [
    {
        icon: <Facebook />,
        path: "https://localhost/ZIKAnalaytics/",
    },
    {
        icon: <Instagram />,
        path: "https://www.instagram.com/zikanalytics/",
    },
    {
        icon: <Twitter />,
        path: "https://twitter.com/ZIKAnalytics",
    },
    {
        icon: <Youtube />,
        path: "https://www.youtube.com/c/ZIKAnalytics",
    },
    {
        icon: <Linkedin />,
        path: "https://www.linkedin.com/company/zik-analytics/",
    },
];

const SocialIcons = () => {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    const handleMouseEnter = (index: number) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    return (
        <div className='flex w-[240px] h-[34px] px-[10px] py-[5px] items-center justify-center gap-[23px]'>
            {socialIcons.map((item, index) => (
                <Link
                    to={item.path}
                    key={item.path}
                    target="_blank"
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    className="cursor-pointer background-transparent flex justify-center items-center rounded-full w-10 h-10" rel="noreferrer"
                >
                    {React.cloneElement(item.icon, {
                        style: hoveredIndex === index ? hoverIconColor : iconColor,
                    })}
                </Link>
            ))}
        </div>
    )
}

export default SocialIcons